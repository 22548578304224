import { globalHistory } from "@reach/router";
import React, { useEffect, useState } from "react";
import { parseParameters, plural } from "../../includes/utils";
import { SearchFilters } from "./SearchFilters";
import { ArticleResultCard } from "./ArticleResultCard";
import "./SearchBody.scss";

export interface ISpaceSearchResult {
  node: {
    slug: string;
    spaceData: {
      title: string;
      labels: string[];
      childPages: IArticleSearchResult[]
    }
  }
}

export interface IArticleSearchResult {
  slug: string;
  articleData: {
    title: string;
    labels: string[];
    createdDate: string;
    readTime: number;
  }
}

interface ISearchProps {
  spaces: ISpaceSearchResult[];
}

const defaultFilter: string = "All Spaces";

const SearchBody = ({ spaces }: ISearchProps): JSX.Element => {
  const [searchResult, setSearchResult] = useState("");
  const [searchTerms, setSearchTerms] = useState([]);
  const [activeFilter, setActiveFilter] = useState(defaultFilter);

  const spaceTitles: string[] = spaces.map((result: ISpaceSearchResult) => result.node.spaceData.title);

  let articles: IArticleSearchResult[] = [];
  for (const space of spaces.filter((edge: ISpaceSearchResult): boolean => edge.node.spaceData.title === activeFilter || activeFilter === defaultFilter)) {
    articles = articles.concat(space.node.spaceData.childPages);
  }

  useEffect(() => {
    const updateSearch = (): void => {
      let { q } = parseParameters();
      if (!q) {
        q = "";
      }

      const result: string = decodeURIComponent(q);
      setSearchResult(result);
      setSearchTerms(result.toLowerCase().split(/[\s-]/g) as never[]);
    };

    updateSearch();
    return globalHistory.listen(updateSearch);
  }, []);

  const shouldDisplayResult = (title: string, labels: string[]): boolean => {
    title = title.toLowerCase();
    const titleWords: string[] = title.split(/[\s-]/g);

    const labelWords: string[] = [];
    for (const label of labels) {
      for (const labelWord of label.split(/[\s-]/g)) {
        labelWords.push(labelWord);
      }
    }

    for (const term of searchTerms) {
      // Titles that start with any search term
      if (title.startsWith(term)) {
        return true;
      }

      // Titles that contain any search term
      for (const titleWord of titleWords) {
        if (titleWord.startsWith(term)) {
          return true;
        }
      }

      for (const labelWord of labelWords) {
        if (labelWord.startsWith(term)) {
          return true;
        }
      }
    }

    return false;
  };

  const getArticles = (): JSX.Element | null => {
    const articleElements: JSX.Element[] = [];
    
    for (const article of articles) {
      const { slug, articleData } = article;
      const { title, labels } = articleData;

      if (!shouldDisplayResult(title, labels)) {
        continue;
      }

      articleElements.push(<ArticleResultCard {...articleData} slug={slug} key={slug}/>);
    }

    if (articleElements.length === 0) {
      return null;
    }

    return <>
      <div className="SearchBody__numberOfResults SearchBody__numberOfResults--articles">Showing {articleElements.length} related {plural("article", articleElements.length)}</div>
      <section className="SearchBody__results SearchBody__results--articles">{articleElements}</section>
    </>;
  };

  const truncatedSearch = (): string => {
    const maxLength: number = 60;
    let truncated: string = searchResult.substring(0, maxLength);

    if (searchResult.length > maxLength) {
      truncated += "...";
    }

    return truncated;
  };

  const getSearchResultHeader = (): JSX.Element => {
    const defaultSearch: boolean = activeFilter === defaultFilter;
    const prefix: string = defaultSearch ? "All" : "Search";
    const postfix: string = defaultSearch ? "" : ` in ${activeFilter}`;

    if (searchResult === "") {
      return <h1>All articles{postfix}</h1>;
    }

    return (
      <h1>{prefix} results for "<span className="SearchBody__query">{truncatedSearch()}</span>"{postfix}</h1>
    );
  };

  const getNoArticlesMessage = (): JSX.Element => {
    let content: string = `We couldn't find any articles related to "${truncatedSearch()}"`;

    if (searchResult === "") {
      content = "We don't currently have any articles in this space. Check back soon!";
    }

    return <div className="SearchBody__noResults">{content}</div>;
  };

  return (
    <div className="SearchBody">
      <SearchFilters spaces={spaceTitles} defaultFilter={defaultFilter} activeFilter={activeFilter} onFilterChange={setActiveFilter}/>
      <div className="SearchBody__content">
        {getSearchResultHeader()}
        {getArticles() ?? getNoArticlesMessage()}
      </div>
    </div>
  );
};
export default SearchBody;