import React from "react";
import { graphql } from "gatsby";
import GlobalLayout from "../components/global/GlobalLayout";
import SearchBody, {ISpaceSearchResult } from "../components/search/SearchBody";

interface ISearchProps {
  data: ISearchQueryData;
}

const Search = ({ data }: ISearchProps): JSX.Element => {
  return (
    <GlobalLayout siteMetadata={data.site.siteMetadata}>
      <SearchBody spaces={data.allConfluenceSpace.edges}/>
    </GlobalLayout>
  );
};
export default Search;

interface ISearchQueryData {
  site: {
    siteMetadata: {
      name: string;
      description: string;
    }
  }
  allConfluenceSpace: {
    edges: ISpaceSearchResult[];
  }
}

export const query = graphql`
  query SearchQuery {
    site {
      siteMetadata {
        name
        description
      }
    }
    allConfluenceSpace {
      edges {
        node {
          slug
          spaceData {
            title
            childPages {
              slug
              articleData {
                title
                labels
                createdDate
                readTime
              }
            }
          }
        }
      }
    }
  }
`;