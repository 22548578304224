import React from "react";
import "./SearchFilters.scss";

interface ISearchFiltersProps {
  spaces: string[];
  defaultFilter: string;
  activeFilter: string;
  onFilterChange: (filter: string) => void;
}

export const SearchFilters = ({ spaces, defaultFilter, activeFilter, onFilterChange }: ISearchFiltersProps): JSX.Element => {
  const createFilter = (title: string): JSX.Element => {
    const checked: boolean = title === activeFilter;
    const className: string = `SearchFilters__input${checked ? " SearchFilters__input--active" : ""}`;

    return (
      <label key={title} className={className}>
        <input type="radio" checked={checked} onChange={(): void => onFilterChange(title)}/>
        <span>{title}</span>
      </label>
    );
  };

  const createSpaceFilters = (): JSX.Element[] => spaces.map((space: string): JSX.Element => createFilter(space));

  return (
    <form className="SearchFilters">
      <h2 className="SearchFilters__title">Filter by Space</h2>
      {createFilter(defaultFilter)}
      {createSpaceFilters()}
    </form>
  );
};